var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-tab", {
        attrs: { type: "vertical", tabItems: _vm.tabItems, height: _vm.height },
        on: {
          "update:height": function ($event) {
            _vm.height = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    process: tab.process,
                    param: _vm.param,
                    planUpdateBtnData: _vm.param.planUpdateBtnData,
                  },
                  on: {
                    "update:process": function ($event) {
                      return _vm.$set(tab, "process", $event)
                    },
                    "update:param": function ($event) {
                      _vm.param = $event
                    },
                    assessComplete: _vm.assessComplete,
                    research: _vm.research,
                    closePopup: _vm.closePopup,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }