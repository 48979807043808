<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :process.sync="tab.process"
          :param.sync="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          @assessComplete="assessComplete"
          @research="research"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checklist-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      editable: true,
      listUrl: '',
    };
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.checklist.targetProcess.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.tabItems = [];
        this.$_.forEach(_result.data, item => {
          if (item && item.targets) {
            this.$_.forEach(item.targets, target => {
              this.$set(target, 'name', target.ramAssessChecklistTargetId);
              this.$set(target, 'icon', 'architecture');
              this.$set(target, 'label', target.parentCheckItemName + '[' + target.checkItemName + ']');
              this.$set(target, 'componentKey', uid());
              this.$set(target, 'component', () => import(`${'./checklistScenario.vue'}`));
              this.$set(target, 'target', target);
            })
          }
          this.tabItems.push({
            key: uid(),
            name: item.processCd, icon: 'work_outline', label: item.processName , process: item, component: () => import(`${'./checklistActionDetail.vue'}`)
          })
        })
        this.tab = _result.data[0].processCd
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'RRS0000015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    }
  }
};
</script>
